<template>
  <div class="business-leads">
    <div class="screen clearfix">
      <div class="item">
        <span>商机名称</span>
        <div class="box">
          <el-input v-model="screen.businessName" placeholder="输入商机名称" style="width: 240px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>企业名称</span>
        <div class="box">
          <el-input v-model="screen.enterpriseName" placeholder="输入企业名称" style="width: 180px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>所属网格</span>
        <div class="box">
          <el-select v-model="screen.grid" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in screen.gridOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>商企经理</span>
        <div class="box">
          <el-select v-model="screen.businessManager" filterable :popper-append-to-body="false" placeholder="全部"
                     style="width: 180px">
            <el-option
                v-for="item in screen.businessManagerOptions"
                :key="item.id"
                :label="item.account"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="item">
        <span>跟进状态</span>
        <div class="box">
          <el-select v-model="screen.status" :popper-append-to-body="false" placeholder="全部" style="width: 180px">
            <el-option
                v-for="item in screen.statusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="button inquire" @click="inquire_btn">查询</div>
        <div class="button" @click="reset_btn">重置</div>
      </div>
    </div>
    <div class="enter-button">
      <div class="btn" style="cursor: pointer" v-if="$store.getters.isItAnAdministrator" @click="skipEnter">录入商机
      </div>
    </div>
    <el-table v-loading="loading" ref="multipleTable" :data="tableData" :border="true" style="width: 100%"
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="60" align="center"></el-table-column>
      <el-table-column prop="sj_id" label="商机ID"></el-table-column>
      <el-table-column prop="name" label="商机名称"></el-table-column>
      <el-table-column prop="grid.name" label="所属网格" min-width="100%"></el-table-column>
      <el-table-column prop="source_name" label="商机来源">
        <!--        <template slot-scope="scope">-->
        <!--          {{ getSocre(scope) }}-->
        <!--        </template>-->
      </el-table-column>
      <el-table-column label="主要业务类型">
        <template slot-scope="scope">
          <span>{{ getBusinesser(scope.row.main_business_type) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="business_manager_name" label="商企经理" width="180">
        <!--        <template slot-scope="scope">
                  {{ getManager(scope) }}
                </template>-->
      </el-table-column>
      <el-table-column prop="create_time" label="上报时间" width="140"></el-table-column>
      <el-table-column prop="follow.update_time" :label="'最后跟进时间'" width="140"></el-table-column>
      <el-table-column prop="status" label="跟进状态" width="90">
        <template slot-scope="scope">
          <span :class="[scope.row.status == 2 ? 'color-blue' : scope.row.status == 1 ? 'color-green' : '']">{{
              getTurn(scope.row.status)
            }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="210">
        <template slot-scope="scope">
          <span><span class="color-blue" style="margin-right: 20px;cursor: pointer"
                      @click="viewDetails(scope)">详情</span></span>
          <span v-if="showOperate(scope.row)" class="color-blue" @click="editorialOpportunity(scope)"
                style="margin-right: 20px;cursor: pointer">编辑</span>
          <span v-if="showOperate(scope.row)" class="color-blue"
                @click="followUp(scope)" style="margin-right: 20px;cursor: pointer">跟进</span>
          <span v-if="showOperate(scope.row)" class="color-blue"
                @click="changeApproval(scope)" style="cursor: pointer">转为立项</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="isIndeterminate" v-model="allSelect" @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="turnedApproval">转为立项</div>
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="limit"
          layout="prev, pager, next, sizes"
          :total="count">
      </el-pagination>
    </div>

    <!--    弹窗-->
    <el-dialog
        class="follow_closely"
        title="添加跟进记录"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
      <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="textarea"
          maxlength="100"
          show-word-limit
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="follow_up_confirmation">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  business_add_follow,
  business_list,
  business_type_info,
  business_update_status
} from "../../request/businessOpportunityPool";

export default {
  name: 'BusinessLeads',
  data() {
    return {
      loading: true,
      dialogVisible: false,
      textarea: '',
      shang_id: null,
      scoreInfo: null,
      allBusiness: {
        businessBasicsType: {},
        businessInnovateType: {},
        businessInternationalType: {}
      },
      screen: {
        businessName: '',
        enterpriseName: '',
        grid: '',
        gridOptions: [],
        businessManager: '',
        businessManagerOptions: [],
        status: '',
        statusOptions: [
          {
            value: '2',
            label: '已转立项'
          },
          {
            value: '1',
            label: '跟进中'
          }
        ]
      }, // 筛选条件
      tableData: [], // 表格数据
      isIndeterminate: false, // 表格是否有选中但未全选
      allSelect: false, // 表格全选
      multipleSelection: [], // 表格选中列表
      currentPage: 1,
      count: null,
      limit: 10,
      page: 1
    }
  },
  mounted() {
    this.getList();
    //获取网格和商企经理数据

    this.$api.Grids().then(res => {
      this.$set(this.screen, 'gridOptions', res.data)
    })

    this.$api.business_type_info().then(res => {
      this.allBusiness.businessBasicsType = res.data.businessBasicsType
      this.allBusiness.businessInnovateType = res.data.businessInnovateType
      this.allBusiness.businessInternationalType = res.data.businessInternationalType
      this.scoreInfo = res.data.sourceInfo //获取所有的商机
      this.screen.businessManagerOptions = res.data.user //获取所有的商企经理
      this.loading = false //loading结束
    });

    /*this.$api.business_type_info().then(res => {
      this.scoreInfo = res.data.sourceInfo //获取所有的商机
      this.screen.businessManagerOptions = res.data.user //获取所有的商企经理
      this.loading = false //loading结束
    })*/

  },
  methods: {
    showOperate(row) { //显示操作按钮
      let result = false;
      let userInfo = this.$store.state.userInfo;
      if (row.status === 1 && userInfo.role_id === 1 && userInfo.admin_id === row.business_manager_id) {
        result = true;
      } else if (row.status === 1 && (userInfo.role_id === 2 || userInfo.admin_id === 1)) {
        result = true;
      }
      return result;
    },
    getBusinesser(type) {
      let result = this.findMatchingIds(this.allBusiness, type) //修改主要业务的值
      return result;
    },
    findMatchingIds(allBusiness, main_business_type) { //获取主要业务的方法
      let matchingIds = null;
      for (let key in allBusiness) {
        let obj = allBusiness[key];
        if (obj.hasOwnProperty('list')) {
          let list = obj.list;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.hasOwnProperty('id') && item.id === main_business_type) {
              matchingIds = item.dict_name
            }
          }
        }
      }
      return matchingIds;
    },
    getSocre(scorp) { //根据id获取商机来源
      let source = null
      if (this.scoreInfo) {
        const selectedSource = this.scoreInfo.find(scurce => scurce.id === scorp.row.source_id);
        if (selectedSource) {
          source = selectedSource.dict_name
        }
      }
      return source
    },
    getManager(scorp) {
      let account = null;
      if (this.screen.businessManagerOptions) {
        const selectedManager = this.screen.businessManagerOptions.find(manager => manager.id === scorp.row.business_manager_id);
        // 如果找到了匹配的商企经理对象，则输出其 account 属性
        if (selectedManager) {
          account = selectedManager.account
        }
      }
      return account
    },
    viewDetails(scope) {
      // 检查 scope.row 和 scope.row.grid 是否存在
      console.log(scope)
      if (scope.row && scope.row.grid && scope.row.grid.name) {
        let data = {
          id: scope.row.id,
          company: scope.row.company.company,
          grid: scope.row.grid.name
        };
        this.$router.push({path: '/businessDetail', query: data});
      } else {
        // 如果 scope.row.grid.name 为空，您可以选择处理这种情况，比如弹出错误提示或采取其他措施
        this.$message({
          message: '该商机未关联网格！！！！',
          type: 'warning'
        });
        // 或者执行其他操作，比如显示错误消息
      }
    },
    getTurn(status) {
      if (status == 1) {
        return '跟进中';
      } else if (status == 2) {
        return '已转立项'
      }
    },
    handleClose(done) { //弹窗
            done();
    },
    followUp(scrop) { //跟进按钮
      this.dialogVisible = true
      this.shang_id = scrop.row.id //跟进ID有点模糊
    },
    follow_up_confirmation() { //确认发送请求
      this.dialogVisible = false
      let data = {
        business_id: this.shang_id,
        content: this.textarea
      }
      this.$api.business_add_follow(data).then(res => {
        if (res.status == 200) {
          this.$message({
            message: '跟进成功！',
            type: 'success'
          });
          this.textarea = ''
        }
      })
    },
    turnedApproval() { //全部转为立项
      let idArray = [];
      this.multipleSelection.map(item => {
        idArray.push(item.id);
      });
      let data = {
        id: idArray
      }
      this.$api.business_update_status(data).then(res => {
        if (res.status == 200) {
          this.getList();
          this.$message({
            type: 'success',
            message: '转为立项成功!'
          });
        }
      })
    },
    inquire_btn() { //查询
      this.loading = true
      this.getList();
    },
    reset_btn() {
      this.screen.businessName = ''
      this.screen.businessManager = ''
      this.screen.enterpriseName = ''
      this.screen.enterpriseName = ''
      this.screen.grid = ''
      this.screen.status = ''

      this.inquire_btn()
    },
    changeApproval(scrop) { //转为立项按钮
      this.$confirm('确定转为立项吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let id = scrop.row.id
        let data = {
          id: [id]
        }
        this.$api.business_update_status(data).then(res => {
          if (res.status == 200) {
            this.getList();
            this.$message({
              type: 'success',
              message: '转为立项成功!'
            });
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消转为立项'
        });
      });
    },
    getList() {
      const data = {
        page: this.page,
        limit: this.limit,
        status: this.screen.status,
        name: this.screen.businessName,
        business_manager_id: this.screen.businessManager,
        company: this.screen.enterpriseName,
        grid_id: this.screen.grid
      }
      this.$api.business_list(data).then(res => {
        this.count = res.data.count;
        this.tableData = res.data.list
        console.log(this.tableData, 4578)
        if (res.status == 200) {
          this.loading = false
        }
        console.log(res, 145)
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.isIndeterminate = val.length > 0 && val.length < this.tableData.length;
      this.allSelect = val.length === this.tableData.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.loading = true
      this.limit = val
      this.getList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.loading = true
      this.page = val
      this.getList();
    }, // 分页currentPage改变
    skipEnter() {
      this.$router.push({path: '/enter'});
    },
    editorialOpportunity(scope) {//编辑商机
      console.log(scope)
      const data = {
        id: scope.row.id,
        gridName: scope.row.grid.name,
        company: scope.row.company.company
      }
      this.$router.push({path: '/editorial', query: data})
    }
  }
}
</script>

<style lang="scss" scoped>
.business-leads {
  margin: 18px;
  padding: 30px 20px;
  background: #FFFFFF;

  .screen {
    padding-bottom: 10px;

    .item {
      float: left;
      margin-bottom: 10px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;
      }

      span {
        float: left;
        margin-right: 12px;
        font-size: 12px;
        color: #21333F;
        line-height: 34px;
      }

      .box {
        float: left;
      }
    }

    .button {
      float: left;
      margin-left: 10px;
      width: 70px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #EEF1F3;
      border-radius: 4px;
      color: #6C7880;
      cursor: pointer;
    }

    .inquire {
      color: #FFFFFF;
      background: #3984F5;
    }
  }

  .enter-button {
    display: flex;
    margin-bottom: 18px;

    .btn {
      width: 80px;
      height: 34px;
      line-height: 34px;
      text-align: center;
      background: #3984F5;
      border-radius: 4px;
      color: #FFFFFF;
    }
  }

  .el-table {
    .color-green {
      color: #04CD83;
    }

    .color-blue {
      color: #3984F5;
    }
  }

  .operate {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .handle {
      display: flex;

      .all-select {
        margin-right: 16px;
        padding-left: 24px;
        line-height: 32px;
      }

      .button {
        margin-right: 10px;
        padding: 0 12px;
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        color: #6C7880;
        border: 1px solid #CEDAE0;
        cursor: pointer;

        &:hover {
          color: #3984F5;
          border-color: #3984F5;
        }
      }
    }
  }
}

// 输入框样式修改
::v-deep .el-input {
  font-size: 12px;

  .el-input__inner {
    padding: 0 12px;
    height: 34px;
    line-height: 34px;
    border: 1px solid #CEDAE0;
    color: #242D33;
  }
}

// 下拉框样式修改
::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: #CEDAE0;
  }

  .el-input__icon {
    font-size: 12px;
    line-height: 34px;
  }
}

// 下拉悬浮框样式修改
::v-deep .el-select-dropdown {
  border-color: #CEDAE0;
  box-shadow: 0 0 12px rgba(36, 45, 51, 0.15);

  .el-select-dropdown__item {
    font-size: 12px;
    color: #242D33;
  }

  .el-select-dropdown__item.hover {
    background-color: transparent;
  }

  .el-select-dropdown__item:hover {
    background-color: #F6F7F9;
  }

  .el-select-dropdown__item.selected {
    color: #3984F5;
    font-weight: 400;
    background-color: #F6F7F9;
  }

  .el-select-dropdown__empty {
    font-size: 12px;
  }
}

// 表格样式修改
::v-deep .el-table {
  font-size: 12px;
  color: #242D33;
  border-color: #DCE5EC;

  &::before,
  &::after {
    background-color: #DCE5EC;
  }

  .el-table__cell {
    padding: 8px 0;
    border-color: #DCE5EC;
  }

  .cell {
    line-height: 24px;
  }

  thead {
    color: #242D33;
  }

  th.el-table__cell.is-leaf {
    border-color: #DCE5EC;
    background: #F6F7F9;
  }

  th {
    font-weight: 400;
  }

  .el-table-column--selection .cell {
    padding-right: 10px;
  }

  .el-table__body tr:hover > td.el-table__cell {
    background-color: #F6FBFE;
  }

  td.el-table__cell {
    padding: 13px 0;
  }
}

// 分页样式修改
::v-deep .el-pagination {
  padding: 0;
  color: #242D33;
  font-weight: 400;

  button {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .el-pager li {
    font-size: 12px;
    height: 32px;
    line-height: 32px;
  }

  .btn-next, .btn-prev, .el-pager li {
    border: 1px solid #CEDAE0;
    background-color: #ffffff;
    color: #242D33;
    min-width: 32px;
  }

  .btn-next:not(:disabled), .btn-prev:not(:disabled), .el-pager li {
    &:hover {
      color: #3984F5;
      border-color: #3984F5;
    }
  }

  .el-pager li:not(.disabled).active {
    background-color: #3984F5;
    border-color: #3984F5;
  }

  .btn-next.disabled, .btn-next:disabled, .btn-prev.disabled, .btn-prev:disabled, .el-pager li.disabled {
    color: #E1E8EC;
  }

  .el-pagination__sizes {
    margin-right: 0;
    height: 32px;
    line-height: 32px;
  }

  .el-select .el-input {
    margin-right: 0;

    .el-input__inner {
      font-size: 12px;
      height: 32px;
      line-height: 32px;

      &:hover {
        border-color: #3984F5;
      }
    }
  }

  .el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner {
    border-color: #3984F5;
  }
}

//弹窗样式
::v-deep .follow_closely {
  .el-dialog {
    margin-top: 30vh !important;
    width: 500px !important;

    .el-textarea__inner {
      height: 136px;
    }

    .el-dialog__footer {
      text-align: center;
    }
  }
}
</style>
